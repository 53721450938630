import { useNuxtApp } from "nuxt/app";
import { defineStore } from "pinia";
import axios from "axios";
import _ from "lodash";
import helper from "../resources/js/libraries/helper";
import { useSsrFetch } from "../composables/useFetch";
import { useClientFetch } from "../composables/useClientFetch";
import { useLayoutStore } from "./layout.store";
import { useSettingsStore } from "./settings.store";
import type { FeatureItem, FilterItem, FilterOptions, FilterPair } from "../types/filters.type"

export const useFiltersStore = defineStore("filters", {
	state: () => ({
		filter: {} as FilterItem,
		listings_data: [],
		listing: {},
		listings_count: 0,
		selected_filters: {
			purpose: {},
			category: {},
			city: {},
			baths: [] as string[],
			bedrooms: {},
		} as FilterOptions,
		is_live: false,
		urlDefaults: {} as FilterOptions,
		filter_url: ``, // update locale
		sort_string: "",
	}),
	getters: {
		getFilters(state) {
			return state.filter;
		},
	},
	actions: {
		// changeChildrenCities(children_cities) {
		// 	this.listings_data.children_cities = children_cities;
		// },
		// update locale
		defaultFilters(): FilterOptions {
			const settingsStore = useSettingsStore();
			return {
				city: {
					label: useNuxtApp().$i18n.t("client.filters_default_location"),
					value: settingsStore.settings.seo_default_slug_location,
				},
				category: {
					label: useNuxtApp().$i18n.t("client.filters_default_category"),
					value: {
						slug: settingsStore.settings.seo_default_slug_category
					},
				},
				purpose: {
					label: useNuxtApp().$i18n.t("client.filters_default_purpose"),
					value: {
						slug: settingsStore.settings.seo_default_slug_purpose
					}
				},
			};
		},
		async fetchFilters(id:number|string) {
			this.urlDefaults = this.defaultFilters();
			this.filter_url = `${this.urlDefaults.category?.value!.slug}/${this.urlDefaults.purpose?.value!.slug}/${this.urlDefaults.city.value}`;
			
			const res = await useSsrFetch(`data/filters/${id || this.urlDefaults.category?.value?.slug}`);

			this.filter = res;
		},
		async fetchCategories() {
			const res = await useSsrFetch(`data/filters/categories`);

			// this.filters = res;
			return res;
		},
		async fetchCount() {
			let query = {};
			const query_str = this.filter_url?.split('?') && this.filter_url?.split('?')[1];
			if (query_str) {
				query = query_str.split('&').reduce((acc: any, pair) => {
					const [key, value] = pair.split('=');
					acc[key] = value;
					return acc;
				}, {});
			}

			const qs = btoa(JSON.stringify({ ...query, only_count: true }));
			const slug = btoa(JSON.stringify(this.filter_url?.split('?')?.[0]?.split('/')?.filter(Boolean)));
			const res: any = await useClientFetch(`pages/slug?slug=${slug}&qs=${qs}`);

			if (!res.data) {
				return;
			}
			this.listings_count = res.data?.total || 0;
		},
		async updateValue(filter: FeatureItem, val: { min: number | string, max: number | string, label_value: string, slug: string }) {
			

			let label = filter.name;
			let value: any = { ...val };

			if (value.min == 0) value.min = "";
			if (value.max == 0) value.max = "";

			if (value.max && value.max != "")
				value.max = helper.currencyFormat(value.max);
			if (value.min && value.min != "")
				value.min = helper.currencyFormat(value.min);

			if (filter.is_range === true) {
				label =
					`${filter.name} ${helper.formatPriceUnits(String(value.min))} : ` +
					(!!value.max ? helper.formatPriceUnits(String(value.max)) : useNuxtApp().$i18n.t("client.any")); // update locale
			} else if (Array.isArray(val)) {
				label = _.map(val, "label_value").join(",") + " " + filter.name;
			} else {
				label = value.label_value || value.value;
			}
			if (filter.input_type === "select" || filter.input_type === 'radio') {
				// value = _.map(val, "label_value").join(",");
				value = val;
			} else if (value.slug) {
				value = value.slug;
			}

			this.selected_filters[filter.slug] = {
				label: label,
				value: value,
			};

			this.updateFilterUrl();
			await this.fetchCount();
		},
		async updateCategory(category:any) {
			let label = category.name;
			let value: any = { ...category };
			
			label = category.name;

			this.selected_filters['category'] = {
				label: label,
				value: {
					slug: category.slug
				},
			};
			
			this.updateFilterUrl();
			await this.fetchCount();
		}

		,
		async updateFilterUrl() {
			let category: string;
			let purpose: string;
			let location: string;

			if (!!this.urlDefaults) {
				this.urlDefaults = this.defaultFilters();
			}

			if (this.selected_filters.category && this.selected_filters.category.value) {
				category = this.selected_filters.category.value.slug;
			} else {
				category = this.urlDefaults.category!.value.slug;
			}

			if (this.selected_filters.purpose && this.selected_filters.purpose.value) {
				purpose = this.selected_filters.purpose.value.slug;
			} else {
				purpose = this.urlDefaults.purpose!.value.slug;
			}
          
		   
			if (this.selected_filters.city && this.selected_filters.city.value) {
				location = this.selected_filters.city.value;
			} else {
				location = this.urlDefaults.city!.value;
			}

			this.filter_url = `${category}/${purpose}/${location}`;

			if (!this.filter) {
				return;
			}
			for (const feature of this.filter.features) {
				const ignore = ["category", "purpose", "city"];
				if (ignore.indexOf(feature.slug) > -1 || feature.is_range) {
					continue;
				}
				const attr_val = this.selected_filters[feature.slug]?.value;
				const filter_val =
					typeof attr_val === "string"
						? attr_val
						: _.map(attr_val, "slug").join(",");
				this.filter_url += filter_val ? `/${filter_val}-${feature.slug}` : "";
			}
			this.filter_url += "/";
			let query = [];
			for (const feature of this.filter.features) {
				if (!feature.is_range) {
					continue;
				}
				const filter_val = this.selected_filters[feature.slug]?.value;
				if (!filter_val?.min && !filter_val?.max) {
					continue;
				}
				if (filter_val.min) {
					query.push(`${feature.slug}_min=${filter_val.min}`);
				}
				if (filter_val.max) {
					query.push(`${feature.slug}_max=${filter_val.max}`);
				}
			}
			this.filter_url += query ? `?${query.join("&")}` : "";
			this.filter_url = _.trim(this.filter_url, "?");
		},
		async setSelectedFilters(selected_filters: FilterOptions) {
			this.selected_filters = selected_filters || this.defaultFilters();
		},
		async fetchListing(slug: string) {
			return await useSsrFetch(`pages/listings/${slug}`) || {};
		},
		resetSelectedFilters() {
			this.selected_filters = this.defaultFilters();
			this.selected_filters = {
				...this.selected_filters,
				bedrooms: [] as any,
				baths: [] as any,
			};

			this.updateFilterUrl();
		}
		// async setListingsData(listings_data) {
		// 	this.listings_data = listings_data;
		// 	this.listings_count = this.listings_data.pagination?.total || 0;
		// },
		// async sortListings(sort_by, sort_direction, str) {
		// 	this.sort_string = str;

		// 	let url_obj = new URL(window.location.href);
		// 	url_obj.searchParams.set("sort_by", sort_by);
		// 	url_obj.searchParams.set("sort_direction", sort_direction);
		// 	url_obj.searchParams.delete("page");
		// 	const url = url_obj.toString();

		// 	axios
		// 		.get(url, {
		// 			headers: {
		// 				"Only-Listings": true,
		// 			},
		// 		})
		// 		.then((res) => {
		// 			const data = res.data;
		// 			this.listings_data.listings = data.listings;
		// 			Inertia.visit(url);
		// 		});
		// },
		// async reset(slug) {
		// 	return false;
		// },
	},
});
